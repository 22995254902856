import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';

export const UploadImage = async function (file, name) {
    if (file && name) {
        const storage = getStorage();
        const storageRef = ref(storage, `gs://cooking-snakes.appspot.com/location-images/${name}`);
        try {
            const snapshot = await uploadBytes(storageRef, file);
            const url = await getDownloadURL(snapshot.ref);
            return url;
        } catch (error) {
            console.log(error);
        }
    } else {
        console.log('Rejected file');
    }
};

export const DeleteImage = function (name) {
    const storage = getStorage();
    const desertRef = ref(storage, `gs://cooking-snakes.appspot.com/location-images/${name}`);

    deleteObject(desertRef)
        .then(() => {
            // File deleted successfully
        })
        .catch((error) => {
            console.log(error);
        });
};

export const FileUidToUrl = async function (uid) {
    try {
        const storage = getStorage();
        const pathReference = ref(storage, `gs://cooking-snakes.appspot.com/location-images/${uid}`);
        return await getDownloadURL(pathReference);
    } catch (error) {
        console.log(error);
    }
};
