import { getAuth, signOut, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";


export const EmailPassRegister = async function(email, password) {
  const auth = getAuth();
  const userCredential = await createUserWithEmailAndPassword(auth, email, password)
  return userCredential.user;
}

export const EmailPassLogin = async function(email, password) {
  const auth = getAuth();
  const userCredential = await signInWithEmailAndPassword(auth, email, password)
  return userCredential.user;
}

export const SignOut = async function() {
  try {
    const auth = getAuth();
    await signOut(auth)
  } catch(error) {
    console.error('Logout error', error)
  }
}

export const ValidateEmail = function(email) {
  return String(email)
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
}
