<template>
  <div>
    <h1>Say Hello to the Team!</h1>
    <div>
      <ProfilePhoto class="batman" name="Arian" />
      <ProfilePhoto class="robin" name="Mike" />
    </div>
  </div>
</template>

<script>
import ProfilePhoto from "../components/PersonProfile.vue";
export default {
  components: {
    ProfilePhoto
  }
};
</script>

<style scoped>
.batman {
  display: inline-block;
  /* width: 180px; */
  margin: auto;
  /* margin-right: 15%; */
}
.robin {
  display: inline-block;
  /* width: 180px; */
  margin: auto;
  text-align: left;
}
</style>
