import "bootstrap/dist/css/bootstrap.css"
import { createApp } from "vue"
import App from "./App.vue"
import * as bootstrap from "bootstrap/dist/js/bootstrap.js"
import axios from "axios"
import createRouter from "./router"
import store from "./store"

// We just need to import but not use this to initialize firebase
import { firebaseApp } from "./utils/firebase/Init.js";  // eslint-disable-line


const axiosInstance = axios.create({
  withCredentials: true,
})
window.bootstrap = bootstrap;
const router = createRouter(store)
const app = createApp(App).use(router).use(store)
app.config.globalProperties.$axios = { ...axiosInstance }
app.mount('#app')

window.newRecipeFlag = false;
