<template>
  <div class="drop-zone">
    <input type="file" ref="file" style="display: none" @change="onSelect"/>
    <div :class="errorMsg ? 'inner-div shake' : 'inner-div' ">
      <div :class="active === false ? 'upload-zone inactive' : 'upload-zone active'"
      :data-active="active"
      @click="$refs.file.click()"
      @dragenter.prevent="setActive"
      @dragover.prevent="setActive"
      @dragleave.prevent="setInactive"
      @drop.prevent="onDrop">
      <span>
        <img v-show="!uploading" class="upload-img" :src="require('@/assets/upload.svg')" alt="upload"/>
        <div v-if="uploading" class="upload-img spinner-border" role="status">
          <span class="sr-only"></span>
        </div>
        <p>
          {{ textBoxMsg() }}
        </p>
      </span>
    </div>
  </div>
</div>
</template>

<script>
import { UploadImage } from "../utils/firebase/storage/ImageStorageManager.js";

const events = ["dragenter", "dragover", "dragleave", "drop"]
let inActiveTimeout = null

export default {
  data() {
    return {
      active: false,
      uploading: false,
      errorMsg: '',
      boxMsg: ''
    }
  },
  props: {
    selectedFile: null,
    uploadName: null
  },
  methods: {
    // setActive/setInactive have timeouts to avoid too many refreshes per sec which causes flicker
    setActive() {
      this.active = true
      clearTimeout(inActiveTimeout)
    },
    textBoxMsg() {
      if (this.selectedFile && typeof this.selectedFile == 'string') {
        this.boxMsg = `Selected: ${this.selectedFile}`
      } else {
        this.boxMsg = (this.errorMsg ? this.errorMsg + ". " : "") + "Choose file or drag it here"
      }
      return this.boxMsg
    },
    generateUUID() { // Public Domain/MIT
      var d = new Date().getTime();//Timestamp
      var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
          r = (d + r)%16 | 0;
          d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
          r = (d2 + r)%16 | 0;
          d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
    },
    setInactive() {
      inActiveTimeout = setTimeout(() => {
        this.active = false
      }, 50)
    },
    async onDrop(e) {
      this.setInactive()
      // For some reason if I dont save the file as a copy of the event, it fails to emit
      // const file = e.dataTransfer.files[0]
      await this.verifyAndUpload(e.dataTransfer.files)
    },
    async onSelect() {
      var input = document.querySelector('input[type=file]');
      await this.verifyAndUpload(input.files)
    },
    preventDefaults(e) {
      e.preventDefault()
    },
    async verifyAndUpload(files) {
      this.uploading = true
      this.errorMsg = ''
      this.boxMsg = ''

      try {
        // TODO file type check
        if (files.length !== 1) {
          throw "You can only select one file"
        } else if (files[0].size >= 5000000000) {
          throw "File must be smaller than 5Mb"
        } else {
          const uploadName = this.uploadName || this.generateUUID()
          const url = await UploadImage(files[0], uploadName)
          this.$emit("file-uploaded", {name: files[0].name, url: url, id: uploadName})
          this.boxMsg = `Selected: ${files[0].name}`
        }
      } catch (error) {
        this.errorMsg = error
      }
      this.uploading = false
      return this.errorMsg ? false : true
    }
  },
  mounted() {
    events.forEach((eventName) => {
      document.body.addEventListener(eventName, this.preventDefaults)
    })
  },
  unmounted() {
    events.forEach((eventName) => {
      document.body.removeEventListener(eventName, this.preventDefaults)
    })
  },
}
</script>

<style scoped>
.shake {
  animation: shake 0.5s;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.inner-div {
}

.upload-zone {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #222222;
  transition: .2s ease;
}

.inactive {
  width: 400px;
  height: 120px;
  margin: 0px;
  background-color:#cce6ff;
  outline: 5px solid #cce6ff;
}

.active {
  width: 394px;
  height: 114px;
  margin: 3px;
  background-color: #eee;
  outline: 8px solid #eee;
}

.drop-zone {
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 6px;
  margin-right: 0px;
}

.upload-img {
  width: 35px;
  height: 35px;
  margin-bottom: 5px;
}
</style>
