
<template>
<div class="bg-image" :style="{ backgroundImage: 'url(' + require('@/assets/food-plate-banner.jpg') + ')', height: '50vh', backgroundSize: 'cover' }" >
  <div class="row height d-flex justify-content-center align-items-center">
    <div class="col-md-8">
    </div>
  </div>
</div>
  <AboutView/>
</template>

<script>
import AboutView from '@/views/AboutView.vue'

export default {
  name: "HomeView",
  components: {
    AboutView
  },
  data() {
    return {
      recipes: {},
      searchTerms: ""
    };
  },
  methods: {
  }
};
</script>

<style scoped>
@media(min-width: 1400px) {
  .search {
    margin-top: 30%;
  }
  .inlineCard {
    padding-left: 2%;
    padding-right: 2%;
  }
}

@media (max-width: 1399px) {
  .search {
    margin-top: 50%;
  }
}
.inlineCards{
  margin-top: 50px;
}
.inlineCard{
  display: inline-block;
  /* width: 180px; */
  margin-left: auto;
}

</style>
