<template>
  <div id="div">
    <NavBar />
    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";

export default {
  name: "App",
  components: {
    NavBar
  }
}
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  caret-color: transparent;
}

h1 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 34px;
  text-align: left;
  margin-top: 50px;
  margin-bottom: 40px;
  color: #36332a;
}

.btn-lg:hover .btn-primary:hover {
  border: solid #ffc72c 1px;
}

.btn .btn-primary .btn-lg {
  background-color: #ffc72c;
  border: solid #ffc72c 1px;
}

.btn-lg {
  background-color: #ffc72c;
  border: solid #ffc72c 1px;
}


.button-spacing{
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

@media(min-width: 1400px) {
  .container {
    max-width: 1450px;
  }
}
</style>
