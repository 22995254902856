import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { EmailPassRegister, EmailPassLogin, SignOut, ValidateEmail } from "../utils/firebase/auth/AuthUtil.js";
import { getAuth, onAuthStateChanged } from "firebase/auth";

// import { toRaw } from 'vue';

const store = createStore({
  plugins: [createPersistedState({storage: window.sessionStorage,})],
  state: {
    routerObj: null,
    user: null,
  },
  mutations: {
    updateUser(state, user) {
      /* Helper function to update current user after logging in */
      if (user != state.user) {
        state.user = user
      }
    },
  },
  actions: {
    async logout({ commit, dispatch }) {
      /* Logs user out */
      return SignOut().then(() => {
        dispatch('refreshUser')
        return commit("updateUser", null)
      }).catch((error) => {
        console.error('logout error', error)
      })
    },
    async refreshUser({ state }) {
      /* Re-syncs current user's status from server */
      const auth = getAuth();
      return onAuthStateChanged(auth, (user) => {
        state.user = user
      });
    },
    login({ commit }, payload) {
      /*
      Logs user in. Returns a promise which will resolve if login was succesful
      Raises error with a slightly modified error message returned by firebase if there is an error
      */
      return new Promise((resolve, reject) => {
        if (!ValidateEmail(payload.email)) {
          reject('Invalid email')
        }

        EmailPassLogin(payload.email, payload.password).then((response) => {
          commit("updateUser", response)
          resolve()
        }).catch((error) => {
          let errMsg = error.message
          errMsg = errMsg.match(/(?:Firebase: Error \()?(?:auth\/)?([\w-_\\/.]*)\)?/);
          errMsg = errMsg[1].replace(/[-_]/g, ' ')
          reject(errMsg.charAt(0).toUpperCase() + errMsg.slice(1))
        })
      })
    },
    register({ commit }, payload) {
      /*
      Registers user. Returns a promise which will resolve if login was succesful
      Raises error with a slightly modified error message returned by firebase if there is an error
      */
      return new Promise((resolve, reject) => {
        if (!ValidateEmail(payload.email)) {
          reject('Invalid email')
        }

        EmailPassRegister(payload.email, payload.password).then((response) => {
          commit("updateUser", response)
          resolve()
        }).catch((error) => {
          let errMsg = error.message
          errMsg = errMsg.match(/(?:Firebase: Error \()?(?:auth\/)?([\w-_\\/.]*)\)?/);
          errMsg = errMsg[1].replace(/[-_]/g, ' ')
          reject(errMsg.charAt(0).toUpperCase() + errMsg.slice(1))
        })
      })
    }
  },
  getters: {
    getUser (state) {
      /* Returns current logged in user's obkect; null if no user is logged in*/
      return state.user
    },
    isLoggedIn (state) {
      /* Returns true if a user is authenticated, false otherwise */
      if ("user" in state && state.user === null) {
        return false
      } else {
        return true
      }
    }
  }
})

export default store
