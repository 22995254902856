<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link" to="/">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/about">About</router-link>
          </li>
          <li v-if="!$store.getters.isLoggedIn" class="nav-item">
            <router-link class="nav-link" to="/login">Login</router-link>
          </li>
          <li v-if="$store.getters.isLoggedIn" class="nav-item">
            <a class="nav-link" @click="logout()">Logout</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>


<script>
export default {
  methods: {
    async logout() {
      await this.$store.dispatch("logout")
      this.$router.push('/')
    }
  },
};
</script>

<style scoped>
.nav-item {
  float: left;
  text-align: center;
  padding: 2px 16px;
  text-decoration: none;
  font-size: 18px;
  cursor: pointer;
}
</style>
