import { initializeApp } from "firebase/app";
// Not the variables are loaded via dotenv using next.conf.js in the root dir


export const firebaseApp = initializeApp({
  apiKey: process.env.VUE_APP_GCP_API_KEY,
  type: process.env.VUE_APP_FIREBASE_TYPE,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  privateKeyId: process.env.VUE_APP_FIREBASE_PRIVATE_KEY_ID,
  privateKey: process.env.VUE_APP_FIREBASE_PRIVATE_KEY,
  clientEmail: process.env.VUE_APP_FIREBASE_CLIENT_EMAIL,
  clientId: process.env.VUE_APP_FIREBASE_CLIENT_ID,
  authUri: process.env.VUE_APP_FIREBASE_AUTH_URI,
  tokenUri: process.env.VUE_APP_FIREBASE_TOKEN_URI,
  authProviderX509CertUrl: process.env.VUE_APP_FIREBASE_AUTH_PROVIDER_X509_CERT_URL,
  clientX509CertUrl: process.env.VUE_APP_FIREBASE_CLIENT_X509_CERT_URL
})
