<template>
    <div>
        <!-- <button @click="handleClick">Press me</button> -->
        <div v-if="files && files.url">
            <img :src="files.url" alt="Image" />
            <form @submit.prevent="submitForm">
                <label for="year">Year</label>
                <input v-model="year" type="number" id="year" name="year" required />

                <label for="location">Location</label>
                <input
                    v-model="location"
                    type="text"
                    id="location"
                    name="location"
                    placeholder="Comma separated locations"
                />

                <label for="tags">Tags</label>
                <input v-model="tags" type="text" id="tags" name="tags" placeholder="Comma separated tags" />

                <button type="submit">Submit</button>
            </form>
        </div>
        <div v-else>
            <p>Upload an Image</p>
            <UploadComponent
                class="drop-area"
                @file-uploaded="changeFile"
                :textOverride="null"
                :errorMsg="error"
                :uploading="uploading"
            >
            </UploadComponent>
        </div>
    </div>
</template>

<script>
import UploadComponent from '../components/UploadComponent.vue';
import { createImageDocument } from '../utils/firebase/storage/ImageFireStoreManager.js';
// import { getRandomImages } from '../utils/firebase/storage/ImageFireStoreManager.js';

export default {
    name: 'FileUpload',
    components: {
        UploadComponent,
    },
    data() {
        return {
            year: null,
            location: '',
            tags: '',
            errorMsg: '',
            files: null,
            uploading: false,
        };
    },
    methods: {
        // Sample usage for getting n random documents
        // handleClick() {
        //     getRandomImages(5);
        // },
        async changeFile(files) {
            this.files = files;
        },
        async submitForm() {
            let location = [];
            let tags = [];

            if (this.location) {
                location = this.location.split(',').map((item) => item.trim());
            }

            if (this.tags) {
                tags = this.tags.split(',').map((item) => item.trim());
            }

            await createImageDocument(this.files.id, {
                url: this.files.url,
                year: this.year,
                location: location,
                tags: tags,
            });

            // clean up and reset
            this.year = null;
            this.location = [];
            this.tags = [];
            this.errorMsg = '';
            this.files = null;
            this.uploading = false;
        },
    },
};
</script>

<style scoped>
.drop-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh; /* Adjust the height as needed */
}

form {
    max-width: 400px;
    margin: 0 auto;
}

label {
    display: block;
    text-align: left;
}

input {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
}

button {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #45a049;
}
</style>
