<template>
  <div class="container">
    <LoginBox/>
  </div>
</template>


<script>
import LoginBox from "../components/LoginBox.vue";

export default {
  name: "LoginView",
  components: {
    LoginBox
  },
  data() {
    return {
    }
  },
  methods: {
  }
};
</script>
