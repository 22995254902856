<template>
  <div class="modal fade" id="forgotPassModal" tabindex="-1" aria-labelledby="forgot pasword" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title modal-text" id="forgotPassModal">Forgot Password</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p class="modal-text">*Email Address</p>
          <div class="md-form mb-4 fields">
            <input v-model="email" placeholder="Enter Email Address" @keyup.enter="Login" class="form-control validate"/>
            <p class="error-msg fields mt-1">{{ error }}</p>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="resetPassword()" type="button" class="btn btn-primary" :disabled="emailSent">
            <div v-if="loading" class="spinner-border" role="status">
              <span class="sr-only"></span>
            </div>
            <div v-if="!loading">
              {{ emailSent ? "Sent" : "Reset Password" }}
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { ValidateEmail } from "@/utils/firebase/auth/AuthUtil.js";

export default {
  name: "ForgotPasswordModal",
  data() {
    return {
      email: "",
      error: "",
      emailSent: false,
      loading: false,
    }
  },
  methods: {
    async resetPassword() {
      this.loading = true
      this.error = ""

      try {
        if (ValidateEmail(this.email)) {
          const auth = getAuth();
          await sendPasswordResetEmail(auth, this.email)
          this.emailSent = true
        } else {
          this.error = "Invalid email"
        }
      } catch (error) {
        let errMsg = error.message.match(/(?:Firebase: Error \()?(?:auth\/)?([\w-_\\/.]*)\)?/)
        errMsg = errMsg[1].replace(/[-_]/g, ' ')
        this.error = errMsg.charAt(0).toUpperCase() + errMsg.slice(1);
      }
      this.loading = false
    }
  }
}
</script>

<style lang="css" scoped>
p {
  float: left;
}

.btn:disabled, .btn:focus, .btn:hover {
  background-color: #dddddd;
  color: #36332a;
}

.modal-text {
  margin-left: 2em;
}

.error-text {
  color: red
}
</style>
