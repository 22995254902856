<template>
  <div class="container">
    <RegisterBox/>
  </div>
</template>


<script>
import RegisterBox from "../components/RegisterBox.vue";

export default {
  name: "RegisterView",
  components: {
    RegisterBox
  },
  data() {
    return {
    }
  },
  methods: {
  }
};
</script>
