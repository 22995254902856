<template>
  <div>
    <img :src="require(`@/assets/${name}.png`)" :alt="`${name}_avatar`" />
    <p>{{ name }}</p>
  </div>
</template>

<script>
export default {
  name: "PersonProfile",
  props: {
    name: String
  }
};
</script>

<style scoped>
img {
  border-radius: 50%;
  width: 220px;
  border: 6px solid #27251f;
}

p {
  text-align: center;
}

div {
  padding: 20px;
  border-radius: 5px;
  /* box-shadow: 1px 1px px #36332a; */
}
</style>
