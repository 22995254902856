<template>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold">Register</h4>
      </div>
      <div class="modal-body">
        <div class="md-form mb-4 fields">
          <input v-model="email" placeholder="Email Address" @keyup.enter="Register" class="form-control validate"/>
        </div>
        <div class="md-form fields">
          <input v-model="password" type="password" @keyup.enter="Register" placeholder="Password" class="form-control validate"/>
        </div>
        <i class="error-msg fields mt-1">{{ error }}</i>
      </div>
      <div class="d-flex justify-content-center mb-4">
        <div class="mb-4">
          <button @click="Register()" type="button" class="btn btn-primary">
            <div v-if="loading" class="spinner-border" role="status"><span class="sr-only"></span></div>
            <div v-if="!loading">Register</div>
          </button>
        </div>
        <div>
          <p class="links mt-4">Already a member? <router-link to="login">Sign In</router-link></p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "RegisterBox",
  data() {
    return {
      error: "",
      email: "",
      password: "",
      loading: false,
    }
  },
  methods: {
    async Register() {
      this.loading = true
      this.error = ""

      try {
        await this.$store.dispatch("register", {email: this.email, password: this.password})
        this.$router.push('/')
      } catch(error) {
        this.error = error
      }
      this.loading = false
    }
  }
};
</script>

<style media="screen">
.error-msg {
  color: red;
  float: left;
}

.links {
  float: left;
  margin-left: 2em;
  margin-bottom: 0em;
}

.fields {
  margin-left: 2em;
  margin-right: 2em;
}

.btn:hover {
  background-color: #dddddd;
  color: #36332a;
}
</style>
