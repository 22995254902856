<template>
  <br/>
  <div class="row height d-flex justify-content-center align-items-center">
    <div class="col-md-8">
    </div>
      <div class="zoomist-container">
        <!-- zoomist-wrapper is required -->
        <div class="zoomist-wrapper">
          <!-- zoomist-image is required -->
          <div class="zoomist-image">
            <!-- you can add anything you want to zoom here. -->
            <img :src="imageUrl" alt="img" />
          </div>
        </div>
      </div>
      <div class="mobile-image col-md-4" style="padding-left: 0px;  padding-right: 0px;">
                 <img :src="imageUrl" alt="img" class="img-fluid" />
      </div>
  </div>
  <br/>
  <div id="slider"></div>
  <br/>
  <p v-if="btn" id="selectedYear" class = "h1"> </p>
  <h2 v-if="correct" class='pop-outin-correct'> Spot On! The year is {{ correctGuess }} </h2>
  <h2 v-if="close" class='pop-outin-close'> {{selectedYear}} is very close! The correct answer is {{ correctGuess }}</h2>
  <h2 v-if="far" class='pop-outin-far'> {{selectedYear}} is far off! The correct answer is {{ correctGuess }} </h2>
   <button v-if="btn" type="button" @click="guess()" class="btn btn-primary btn-lg">
      Guess
   </button>
   <p v-if="!btn" class="h4"> {{description}}</p>
  <button v-if="!btn" type="button" @click="next()" class="btn btn-primary btn-lg">
     Next Photo
  </button>
</template>

<script>
// import Zoomist styles
import 'zoomist/css'
// import Zoomist
import Zoomist from 'zoomist'
import * as noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';
import { getAllDocs } from '../utils/firebase/storage/ImageFireStoreManager.js';

export default {
  name: "PhotoGuessrView",
  props: {
     photoView: Object
  },
  async mounted() {
    if( screen.width > 700) {

        new Zoomist('.zoomist-container', {
          // Optional parameters
          maxScale: 5,
          bounds: true,
          // if you need slider
          slider: true,
          // if you need zoomer
          zoomer: true
        })
    }
    var slider = document.getElementById('slider');
    var filterPips = function(value) {
        if(screen.width < 700 && value % 20 === 0) {
            return 1
        }
        else if (screen.width > 700 && value % 10 === 0) {
            return 1;
        }
        return 0;
    }
    noUiSlider.create(slider, {
        range: {
            min: 1900,
            max: 2024
        },
        step: 1,
        behaviour: 'snap',
        connect: [false, false],
        start: [1962],
        pips: {
            mode: 'steps',
            density: 0.8196,
            filter: filterPips
        }
    });
    slider.noUiSlider.on('update', function(values) {
        var year = document.getElementById('selectedYear');
        year.innerText = Math.trunc(values[0]);
    });

    this.docs = await getAllDocs();
    const randomIndex = Math.floor(Math.random() * this.docs.length);
    this.currentItem = this.docs[randomIndex];
    this.correctGuess = this.currentItem.data.year;
    this.imageUrl = this.currentItem.data.url;
    this.description = this.currentItem.data.description;
  },
  methods: {
     guess() {
        var year = document.getElementById('selectedYear').innerText;
        this.selectedYear = year;
        var difference = Math.abs(this.correctGuess - this.selectedYear);
        if(difference == 0) {
            this.correct = true;
        } else if(difference < 10) {
            this.close = true;
        } else {
            this.far = true;
        }
        this.btn = false;
        var slider = document.getElementById('slider');
        slider.noUiSlider.disable();
     },
     next() {
        this.btn = true;
        var slider = document.getElementById('slider');
        slider.noUiSlider.enable();
        setTimeout(() => {
          slider.noUiSlider.reset();
        }, "100");
        this.correct = false;
        this.far = false;
        this.close = false;
        var randomIndex = Math.floor(Math.random() * this.docs.length);
        while(this.currentItem.id == this.docs[randomIndex].id) {
            randomIndex = Math.floor(Math.random() * this.docs.length);
        }
        this.currentItem = this.docs[randomIndex];
        this.correctGuess = this.currentItem.data.year;
        this.imageUrl = this.currentItem.data.url;
        this.description = this.currentItem.data.description;
     }
  },
  data() {
      return {
        btn: true,
        correctGuess: 1982,
        correct: false,
        close: false,
        far: false,
        selectedYear: "ad",
        currentItem: {},
        imageUrl: "https://static.vecteezy.com/system/resources/thumbnails/011/299/153/small/simple-loading-or-buffering-icon-design-png.png",
        description: "",
        docs: {},
        desktop: screen.width>700? true:false,
      }
  },
};
</script>

<style>

 @media screen and (max-width: 700px) {
    .zoomist-container {
        display:none;
    }
    .mobile-image {
        display:block;
    }
 }
  @media screen and (min-width: 700px) {
     .zoomist-container {
         display:block;
     }
     .mobile-image {
        display:none;
     }
  }

.zoomist-container {
  width: 95%;
  max-width: 100%;
}
.zoomist-image {
  width: 100%;
  height: 50%;
  aspect-ratio:3;
}
.zoomist-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

#slider {
    margin-bottom: 3em;
}

.pop-outin-correct {
  animation: 2s anim-popoutin-correct ease;
  font-weight: bold;
}

@keyframes anim-popoutin-correct {
  0% {
    color: green;
    transform: scale(0);
    opacity: 0;
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  25% {
    color: green;
    transform: scale(2);
    opacity: 1;
    text-shadow: 3px 10px 5px rgba(0, 0, 0, 0.5);
  }
  50% {
    color: black;
    transform: scale(1);
    opacity: 1;
    text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    /* animate nothing to add pause at the end of animation */
    transform: scale(1);
    opacity: 1;
    text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
  }
}

.pop-outin-close {
  animation: 2s anim-popoutin-close ease;
  font-weight: bold;
}

@keyframes anim-popoutin-close {
  0% {
    color: yellow;
    transform: scale(0);
    opacity: 0;
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  25% {
    color: yellow;
    transform: scale(2);
    opacity: 1;
    text-shadow: 3px 10px 5px rgba(0, 0, 0, 0.5);
  }
  50% {
    color: black;
    transform: scale(1);
    opacity: 1;
    text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    /* animate nothing to add pause at the end of animation */
    transform: scale(1);
    opacity: 1;
    text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
  }
}

.pop-outin-far {
  animation: 2s anim-popoutin-far ease;
  font-weight: bold;
}

@keyframes anim-popoutin-far {
  0% {
    color: red;
    transform: scale(0);
    opacity: 0;
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  25% {
    color: red;
    transform: scale(2);
    opacity: 1;
    text-shadow: 3px 10px 5px rgba(0, 0, 0, 0.5);
  }
  50% {
    color: black;
    transform: scale(1);
    opacity: 1;
    text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    /* animate nothing to add pause at the end of animation */
    transform: scale(1);
    opacity: 1;
    text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
  }
}
</style>
