import { collection, doc, setDoc, orderBy, limit, query, where, getDocs } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';

export const createImageDocument = async function (key, data) {
    // Create a document refrencing a location image
    try {
        const db = getFirestore();
        // The random int is a workaround for randomly selecting a document
        const random = getRandomInt(10000000);

        await setDoc(doc(db, 'images', key), { data: data, search: random });
        return key;
    } catch (error) {
        console.error('Error creating document:', error);
    }
};

export const getAllDocs = async function () {
    if(sessionStorage.docs) {
        return JSON.parse(sessionStorage.docs)
    } else {
        const results = [];
        const db = getFirestore();
        const collection_ref = collection(db, 'images')
        let q = query(collection_ref);
        var docs = await getDocs(q);
        console.log(docs);
        docs.forEach((doc) => {
            var obj = {};
            obj.data = doc.data();
            obj.id = doc.id;
            results.push(obj);
        });
        sessionStorage.setItem('docs', JSON.stringify(results));
        return results;
    }
}

export const getRandomImages = async function (image_count = 1) {
    // Get a list of random image documents of a given size
    const results = [];

    for (let i = 0; i < image_count; i++) {
        results.push(await getRandomImage());
    }

    return results;
};

async function getRandomImage() {
    // Randomly select an image by doing a wraparound search based on a random serach index
    const db = getFirestore();
    const collection_ref = collection(db, 'images');
    const random = getRandomInt(10000000);
    const results = [];

    let q = query(collection_ref, where('search', '<', random), orderBy('search'), limit(1));
    var docs = await getDocs(q);

    if (docs.empty) {
        let q = query(collection_ref, where('search', '>=', random), orderBy('search'), limit(1));
        docs = await getDocs(q);
    }

    docs.forEach((doc) => {
        results.push(doc.data()['data']);
    });

    return results[0];
}

function getRandomInt(max) {
    // Random number genreator. This is used for randomizing documents
    return Math.floor(Math.random() * max);
}
